import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PersonIcon from '@mui/icons-material/Person';
import { DialogContext } from './context/DialogContext';

const footerMenu = [
    {
        id: 1,
        text: "Explore",
        icon: <SearchIcon color="secondary" />,
    },
    { id: 2, text: "Wishlist", icon: <FavoriteBorderIcon /> },
    { id: 3, text: "Login", icon: <PersonIcon /> },
];

const DefaultFooterMenu = () => {
    // @ts-ignore
    const { setOpen } = useContext(DialogContext);

    return (
        <Stack direction="row" spacing={0}>
            {footerMenu.map((item) => {
                return (
                    <Button key={item.id} onClick={() => setOpen(true)}>
                            <Stack
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                direction="column"
                                spacing={0}
                            >
                                {item.icon}
                                <Typography>{item.text}</Typography>
                            </Stack>
                    </Button>
                );
            })}
        </Stack>
    );
};

export default DefaultFooterMenu;
